import { Box, Flex, HStack, Input } from '@chakra-ui/react';
import KCustomSelect from 'components/k-custom-select/k-custom-select';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { INITIAL_ADDRESS_DATA } from 'constants/address';
import { useFormik } from 'formik';
import { useLocationData } from 'hooks/useLocationData';
import { useEffect, useRef, useState } from 'react';
import { AddressResSchema, AddressValidationSchema, SuggestedAddress } from 'types/address';
import { LocationOptionType } from 'types/location';
import { AddressStatus, AddressType, CountryCodeEnum } from 'types/shared-types';
import { toNormalCase } from 'utils';

import { AddressSuggestionPopover } from './AddressSuggestionPopover';

type AddressFormModalType = {
  isOpen: boolean;
  onClose: () => void;
  payload: AddressResSchema | undefined;
  handleOnSubmit: (values: AddressResSchema) => void;
};

// ToDO: let's try to reuse this in other places. (extend if needed)

export const AddressFormModal = ({ isOpen, onClose, payload, handleOnSubmit }: AddressFormModalType) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { countries, getStates, isLoadingCountries } = useLocationData();
  const [stateOptions, setStateOptions] = useState<LocationOptionType[]>([]);

  const { values, setValues, setFieldValue, handleChange, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: payload
      ? {
          phone: payload?.phone,
          street_1: payload?.street_1,
          street_2: payload?.street_2,
          county: payload?.county,
          state: payload?.state,
          city: payload?.city,
          postal_code: payload?.postal_code,
          country: payload?.country,
          type: payload?.type,
          status: payload?.status,
        }
      : INITIAL_ADDRESS_DATA,
    validationSchema: AddressValidationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      handleOnSubmit(values);
    },
  });

  useEffect(() => {
    const fetchStates = async () => {
      const states = await getStates(values.country ?? '');
      setStateOptions(states);
    };
    fetchStates();
  }, [getStates, values.country]);

  const handleStateChange = (value: string) => {
    setFieldValue('state', value ?? '');
  };

  const handleCountryChange = (value: string) => {
    if (value !== 'US') {
      setFieldValue('state', '');
      setFieldValue('county', '');
    }
    setFieldValue('country', value);
  };

  const applySelectedAddress = (address: SuggestedAddress) => {
    setValues({
      phone: values?.phone || '',
      street_1: values?.street_1 || '',
      street_2: values?.street_2,
      country: address.country || CountryCodeEnum.US,
      type: values?.type || AddressType.SHIP_TO,
      status: AddressStatus.UNVERIFIED,
      city: address.city || '',
      county: address.county || undefined,
      state: address.state,
      postal_code: address.postal_code || '',
    });
  };

  const onCloseModal = () => {
    resetForm();
    onClose();
  };

  return (
    <DialogRoot
      size={'md'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>{payload?.type === 'BILL_TO' ? 'Bill To' : 'Ship To'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form onSubmit={handleSubmit}>
            <HStack align={'baseline'}>
              <Field
                label="Address Line 1"
                invalid={!!(errors.street_1 && touched.street_1)}
                errorText={errors.street_1}
                required
              >
                <Input
                  id="street_1"
                  type="text"
                  name="street_1"
                  value={values.street_1 || ''}
                  onChange={handleChange}
                  placeholder={payload?.street_1}
                />
              </Field>
              <Field label="Address Line 2">
                <Input
                  id="street_2"
                  type="text"
                  name="street_2"
                  value={values.street_2 || ''}
                  onChange={handleChange}
                  placeholder={payload?.street_2}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field
                label="Country"
                invalid={!!(touched.country && touched.country)}
                errorText={touched.country}
                required
              >
                <KCustomSelect
                  id="country"
                  name="country"
                  items={countries}
                  value={values.country ? [values.country] : undefined}
                  onValueChange={({ value }) => handleCountryChange(value[0])}
                  enableSearch
                  searchPlaceholder="Search country"
                  isDataLoading={isLoadingCountries}
                />
              </Field>

              <Field label="State" invalid={!!(errors.state && touched.state)} errorText={errors.state} required>
                <KCustomSelect
                  id="state"
                  name="state"
                  items={stateOptions}
                  value={values.state ? [values.state] : undefined}
                  onValueChange={({ value }) => handleStateChange(value[0])}
                  enableSearch
                  searchPlaceholder="Search state"
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            <HStack align={'baseline'}>
              <Field label="City" invalid={!!(errors.city && touched.city)} errorText={errors.city} required>
                <Input
                  id="city"
                  type="text"
                  name="city"
                  value={toNormalCase(values.city ?? '')}
                  onChange={handleChange}
                  placeholder={payload?.city}
                />
              </Field>

              <Field
                label="Zip"
                invalid={!!(errors.postal_code && touched.postal_code)}
                errorText={errors.postal_code}
                required
              >
                <Input
                  id="postal_code"
                  name="postal_code"
                  value={values.postal_code || ''}
                  onChange={handleChange}
                  placeholder={payload?.postal_code}
                />
              </Field>

              <Field label="County">
                <Input
                  id="county"
                  type="text"
                  name="county"
                  value={toNormalCase(values.county ?? '')}
                  onChange={handleChange}
                  placeholder={payload?.county || ''}
                />
              </Field>
            </HStack>
            <Box height={'16px'} />
            {isOpen && values && (
              <AddressSuggestionPopover address={values} onUpdateAddress={applySelectedAddress} isParentOpen={isOpen} />
            )}
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button colorPalette="blue" width={'90px'} onClick={() => handleSubmit()}>
              Save
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
