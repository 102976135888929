import { TransactionEstimateRequest } from '_api-client';
import { useMutation } from '@tanstack/react-query';
import { calculateTaxEstimate } from 'apis/tax-estimate';
import { AddressFormModal } from 'components/Address/AddressFormModal';
import { formatISO } from 'date-fns';
import { Formik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useEffect, useState } from 'react';
import { CurrencyEnum } from 'schema/types-schema.d';
import { AddressResSchema } from 'types/address';
import {
  InitialTransactionItemType,
  ProductDropdownItemType,
  TaxEstimateValidationSchema,
  TaxEstimateValidationSchemaType,
  TotalDataType,
} from 'types/tax-estimates';
import { generateRandomNumber, replaceNullWithEmptyString } from 'utils';

import CalculatorFormInner from './calculator-form-inner';

const initialTransactionItem: InitialTransactionItemType = {
  external_product_id: '',
  product_description: '',
  quantity: '',
  tax_amount: '',
  amount: '',
  date: formatISO(new Date()),
  tax_items: [],
};

const CalculatorForm = ({
  selectedAddress,
  addressFormOpen,
  onCloseAddressModal,
  handleOnSubmitAddress,
}: {
  selectedAddress: AddressResSchema;
  addressFormOpen: boolean;
  onCloseAddressModal: () => void;
  handleOnSubmitAddress: (address: AddressResSchema) => void;
}) => {
  const { orgId } = useOrg();
  const { handleFailNotification } = useHandleNotification();

  const [isDirty, setIsDirty] = useState(false);
  const [showTotalSection, setShowTotalSection] = useState(false);
  const [loadSavedData, setLoadSavedData] = useState<TransactionEstimateRequest | null>(null);
  const [taxEstimateTotal, setTaxEstimateTotal] = useState<TotalDataType | null>(null);
  const [searchQueries, setSearchQueries] = useState<Record<number, string>>({});
  const [selectedProducts, setSelectedProducts] = useState<Record<number, ProductDropdownItemType>>({});

  const formInitialValues = {
    transaction_items: [initialTransactionItem],
    addresses: [],
  };

  const { mutate: calculateTaxEstimates, isPending: isSubmitLoading } = useMutation({
    mutationFn: (payload: TransactionEstimateRequest) => {
      return calculateTaxEstimate(orgId, payload).then(response => {
        const { data } = response;
        const {
          source,
          external_id,
          date,
          total_amount,
          currency,
          total_tax_amount_calculated,
          addresses,
          transaction_items,
        } = data;
        const formatToFormData = {
          source,
          external_id,
          date,
          total_amount,
          currency,
          addresses: replaceNullWithEmptyString(addresses),
          transaction_items,
        } as TransactionEstimateRequest;

        const taxEstimateTotalData = {
          total_amount,
          total_tax_amount_calculated,
        } as TotalDataType;
        setLoadSavedData(formatToFormData);
        setTaxEstimateTotal(taxEstimateTotalData);
      });
    },
    onSuccess: () => {
      setShowTotalSection(true);
    },
    onError: (error: any) => {
      handleFailNotification(error);
      setShowTotalSection(false);
    },
  });

  useEffect(() => {
    if (isDirty) {
      setShowTotalSection(false); // If form is modified, always reset
    } else if (!isDirty && taxEstimateTotal) {
      setShowTotalSection(true); // If user resets back to original values, show TotalSection again
    }
  }, [isDirty]);

  const handleTotalSectionVisible = (removedProdExtId: string) => {
    const itemIndex = loadSavedData?.transaction_items.findIndex(
      ({ external_product_id }) => external_product_id === removedProdExtId
    );
    if (itemIndex === 1) {
      setShowTotalSection(false);
    } else setShowTotalSection(true);
  };

  const handleFormSubmit = (values: TransactionEstimateRequest | TaxEstimateValidationSchemaType) => {
    const payload: TransactionEstimateRequest = {
      ...values,
      external_id: generateRandomNumber().toString(), // random number for external_id, as actual transaction did not happened yet, BE need that and it will not be saved
      date: new Date().toISOString(),
      total_amount: values.transaction_items.reduce((acc: number, item: any) => acc + Number(item.amount), 0),
      currency: CurrencyEnum.USD,
      transaction_items: values.transaction_items.map((item: any) => ({
        ...item,
        product_name: selectedProducts?.[item.index]?.label,
        product_source: selectedProducts?.[item.index]?.product_source,
        quantity: Number(item.quantity),
        amount: Number(item.amount),
      })),
    };

    calculateTaxEstimates(payload);
  };

  return (
    <Formik
      initialValues={loadSavedData || formInitialValues}
      enableReinitialize
      validationSchema={TaxEstimateValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue }) => (
        <>
          <CalculatorFormInner
            formInitialTransactionItem={initialTransactionItem}
            searchQueries={searchQueries}
            showTotalSection={showTotalSection}
            taxEstimateTotal={taxEstimateTotal}
            isSubmitLoading={isSubmitLoading}
            setIsDirty={setIsDirty}
            setSearchQueries={setSearchQueries}
            setSelectedProducts={setSelectedProducts}
            setShowTotalSection={setShowTotalSection}
            handleTotalSectionVisible={handleTotalSectionVisible}
          />

          {addressFormOpen && (
            <AddressFormModal
              isOpen={addressFormOpen}
              onClose={onCloseAddressModal}
              payload={selectedAddress ?? undefined}
              handleOnSubmit={value => {
                const selectedValue = [...values.addresses];
                const index = selectedValue.findIndex(a => a.type === value.type);

                if (index !== -1) {
                  selectedValue.splice(index, 1, value);
                } else {
                  selectedValue.push(value);
                }

                setFieldValue('addresses', selectedValue, true);
                handleOnSubmitAddress(value);
                setShowTotalSection(false);
              }}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default CalculatorForm;
