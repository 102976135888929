import { Box, HStack, Icon, Input, Text, VStack } from '@chakra-ui/react';
import { DeleteIcon } from 'components/icons';
import { KCustomSelect } from 'components/k-custom-select/k-custom-select';
import { Field } from 'components/ui/field';
import useProductsQuery from 'hooks/useProductQuery';
import { ProductDropdownItemType } from 'types/tax-estimates';
import { formatCurrency } from 'utils/utils';

const CalculatorProductRow = ({
  index,
  transaction_item,
  errors,
  handleChange,
  setFieldValue,
  remove,
  searchQuery,
  setSearchQuery,
  setShowTotalSection,
  setSelectedProducts,
  handleTotalSectionVisible,
}: {
  index: number;
  transaction_item: any;
  errors: any;
  handleChange: any;
  setFieldValue: any;

  remove: (index: number) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  setShowTotalSection: (val: boolean) => void;
  setSelectedProducts: React.Dispatch<React.SetStateAction<Record<number, ProductDropdownItemType>>>;
  handleTotalSectionVisible: (external_product_id: string) => void;
}) => {
  const { data: products, isPending: isLoading } = useProductsQuery(searchQuery);

  return (
    <HStack align={'baseline'} gap={4}>
      <Field
        label="Product"
        invalid={!!errors.transaction_items?.[index]?.external_product_id}
        errorText={errors.transaction_items?.[index]?.external_product_id}
        required
      >
        <KCustomSelect
          enableSearch
          isExternalSearch
          searchPlaceholder="Search product"
          isDataLoading={isLoading}
          searchQuery={searchQuery}
          onChangeSearchQuery={setSearchQuery}
          items={products || []}
          onValueChange={({ value }) => {
            setFieldValue(`transaction_items.${index}.external_product_id`, value[0]);
            const selectedItem = products?.find(p => p.value === value[0]);
            if (selectedItem) {
              setSelectedProducts(prev => ({
                ...prev,
                [index]: selectedItem,
              }));
            }
            const productDesc = selectedItem?.product_description ?? '';
            setFieldValue(`transaction_items.${index}.product_description`, productDesc);
            setShowTotalSection(false);
          }}
          disabled={isLoading}
        />
      </Field>

      <Field label="Description">
        <Text pt={1}>{transaction_item.product_description}</Text>
      </Field>

      <Field
        label="Quantity"
        invalid={!!errors.transaction_items?.[index]?.quantity}
        errorText={errors.transaction_items?.[index]?.quantity}
        required
      >
        <Input
          inputMode="numeric"
          maxLength={10}
          placeholder={'Enter Quantity'}
          name={`transaction_items.${index}.quantity`}
          value={transaction_item.quantity}
          onChange={evt => {
            handleChange(evt);
            setShowTotalSection(false);
          }}
        />
      </Field>

      <Field label="Tax">
        <Text pt={1}>{formatCurrency(transaction_item.tax_amount)}</Text>
      </Field>

      <Field
        label="Amount"
        invalid={!!errors.transaction_items?.[index]?.amount}
        errorText={errors.transaction_items?.[index]?.amount}
        required
      >
        <Input
          inputMode="numeric"
          maxLength={10}
          placeholder={'Enter Amount'}
          name={`transaction_items.${index}.amount`}
          value={transaction_item.amount}
          onChange={evt => {
            handleChange(evt);
            setShowTotalSection(false);
          }}
          textAlign={'right'}
          _placeholder={{ textAlign: 'left' }}
        />
      </Field>

      <VStack minW={'20px'} gap={4}>
        <Box />
        {index > 0 && (
          <Icon
            width={5}
            height={5}
            color={'#E53E3E'}
            cursor={'pointer'}
            onClick={() => {
              remove(index);
              handleTotalSectionVisible(transaction_item.external_product_id);
            }}
          >
            <DeleteIcon />
          </Icon>
        )}
      </VStack>
    </HStack>
  );
};

export default CalculatorProductRow;
