import { Box, HStack, StackSeparator } from '@chakra-ui/react';
import { Button } from 'components/ui/button';
import { Tooltip } from 'components/ui/tooltip';
import { FieldArray, Form, useFormikContext } from 'formik';
import { Fragment, useEffect } from 'react';
import {
  InitialTransactionItemType,
  ProductDropdownItemType,
  TaxEstimateValidationSchemaType,
  TotalDataType,
} from 'types/tax-estimates';

import CalculatorProductRow from './calculator-product-row';
import CalculatorTotalSection from './calculator-total-section';

type CalculatorFormInnerProps = {
  formInitialTransactionItem: InitialTransactionItemType;
  searchQueries: Record<number, string>;
  taxEstimateTotal: TotalDataType | null;
  isSubmitLoading: boolean;
  showTotalSection: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchQueries: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  setSelectedProducts: React.Dispatch<React.SetStateAction<Record<number, ProductDropdownItemType>>>;
  setShowTotalSection: React.Dispatch<React.SetStateAction<boolean>>;
  handleTotalSectionVisible: (removedProdExtId: string) => void;
};

const CalculatorFormInner: React.FC<CalculatorFormInnerProps> = ({
  formInitialTransactionItem,
  searchQueries,
  taxEstimateTotal,
  isSubmitLoading,
  showTotalSection,
  setIsDirty,
  setSearchQueries,
  setSelectedProducts,
  setShowTotalSection,
  handleTotalSectionVisible,
}) => {
  const { handleSubmit, isValid, dirty, errors } = useFormikContext<TaxEstimateValidationSchemaType>();
  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);

  return (
    <Form onSubmit={handleSubmit}>
      <FieldArray name="transaction_items">
        {({ insert, remove, form: { values, handleChange, setFieldValue } }) => (
          <>
            <Box border={'1px solid '} borderColor={'#EFEFF3'} p={4} mt={4} width={'full'}>
              {values.transaction_items.length > 0 &&
                values.transaction_items.map((item: any, index: number) => (
                  <Fragment key={index}>
                    {index > 0 && <StackSeparator mt={4} borderWidth={'1px'} borderColor="#EFEFF3" mb={4} />}
                    <CalculatorProductRow
                      index={index}
                      transaction_item={item}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      setSelectedProducts={setSelectedProducts}
                      remove={remove}
                      searchQuery={searchQueries[index] || ''}
                      setSearchQuery={query => setSearchQueries(prev => ({ ...prev, [index]: query }))}
                      setShowTotalSection={setShowTotalSection}
                      handleTotalSectionVisible={handleTotalSectionVisible}
                    />
                  </Fragment>
                ))}
            </Box>

            <HStack mt={6} justifyContent={'space-between'} alignItems={'flex-start'}>
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  insert(values.transaction_items.length + 1, formInitialTransactionItem);
                  setShowTotalSection(false);
                }}
              >
                + Add Product
              </Button>

              {isValid && showTotalSection ? (
                <CalculatorTotalSection
                  subTotal={taxEstimateTotal?.total_amount ?? 0}
                  taxAmount={taxEstimateTotal?.total_tax_amount_calculated || 0}
                  totalAmount={
                    taxEstimateTotal?.total_amount && taxEstimateTotal?.total_tax_amount_calculated
                      ? Number(taxEstimateTotal.total_amount) + Number(taxEstimateTotal.total_tax_amount_calculated)
                      : 0
                  }
                />
              ) : (
                <Tooltip
                  disabled={isValid && dirty}
                  content={'One address and product item is required to calculate tax'}
                  positioning={{ placement: 'bottom-start' }}
                >
                  <Button type="submit" loading={isSubmitLoading} disabled={!isValid || !dirty}>
                    Calculate Tax
                  </Button>
                </Tooltip>
              )}
            </HStack>
          </>
        )}
      </FieldArray>
    </Form>
  );
};

export default CalculatorFormInner;
