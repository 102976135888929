import { useQuery } from '@tanstack/react-query';
import { getProducts, PRODUCT_SERVICE_QUERY_KEYS } from 'apis/product-apis';
import { ProductInstance, ProductStatusEnum } from 'types/shared-types';
import { ProductDropdownItemType } from 'types/tax-estimates';

import { useOrg } from './useOrg';
import { useTableFilters } from './useTableFilters';

const useProductsQuery = (searchQuery: string) => {
  const { orgId } = useOrg();
  const { status__in } = useTableFilters({
    status__in: [ProductStatusEnum.APPROVED, ProductStatusEnum.PARTIALLY_APPROVED],
  });

  const { data, isPending } = useQuery<ProductDropdownItemType[] | []>({
    queryKey: [PRODUCT_SERVICE_QUERY_KEYS.list(), orgId, status__in, searchQuery],
    queryFn: async () => {
      const res = await getProducts({
        orgId,
        params: { status__in, ...(searchQuery && { query: searchQuery }) },
      });

      return res?.data?.items?.map((product: ProductInstance) => ({
        value: product.external_id,
        label: product.name,
        product_description: product.description,
        product_source: product.source,
      }));
    },
    enabled: true,
  });

  return { data, isPending };
};

export default useProductsQuery;
